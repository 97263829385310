import React, { useEffect, useMemo, useState } from "react";
import {Box, Grid} from "@material-ui/core";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import ReportNav from "../ReportNav";
import {useDispatch} from "react-redux";
import "./CarrierContactReport.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AppInput from "../../../core/components/inputs/AppInput/AppMuiInput";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import CarrierContactListing from "./includes/CarrierContactListing";

const CarrierContactReport = (props) => {

    const dispatch = useDispatch();
    const state = window.shell.stateStore.getState();
    const associate = state.persona.associate;

    const initialSort = [['shipmentBolNumber', 'desc']];

    usePageTitle('Carrier Contact Report');

    const [offset, setOffset] = React.useState(0);
    const [limit, setLimit] = React.useState(20);
    const [sort, setSort] = React.useState(initialSort);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('shipmentBolNumber');

    const [carrierPhoneNumber, setCarrierPhoneNumber] = React.useState(null);
    const [carrierEmailAddress, setCarrierEmailAddress] = React.useState(null);

    const [shipments, setShipments] = useState([]);
    const [shipmentCount, setShipmentCount] = useState(0);

    React.useEffect(() => {
        performSearch();
    }, [offset, limit, sort]);

    const performSearch = async () => {

        const args = {
            filter: {},
            offset,
            limit,
            sort
        };

        if (carrierEmailAddress && carrierEmailAddress.length > 0)
            args.filter['carrierRepEmail'] = carrierEmailAddress;

        if (carrierPhoneNumber && carrierPhoneNumber.length > 0)
            args.filter['carrierRepPhone'] = carrierPhoneNumber;

        const { shipments, count } = await window.shell.gateway.getCarrierHistory(args);
        setShipments(shipments);
        setShipmentCount(count);
    };

    const handleSearchClick = (e) => {
        setOffset(0);
        performSearch();
    };

    const handlePhoneNumberChange = (e) => {
        setCarrierPhoneNumber(e.target.value);
    };

    const handleEmailAddressChange = (e) => {
        setCarrierEmailAddress(e.target.value);
    };

    //Handles when the user changes pages within the table.
    const handlePageChange = (e, page) => {
        setOffset(page * limit);
    };

    // Handles when the user clicks on column headers for sorting.
    const handleSortChange = (column) => {
        const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

        setSort([[column, changeOrder]]);
        setOrder(changeOrder);
        setOrderBy(column);
    };
    const handleChangeRowsPerPage = (e) => {
        setOffset(0);
        setLimit(e.target.value);
    };

    return (
        <FullWidthLayout SideNav={ReportNav} title={'Carrier Contact Report'}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Box>Carrier Contact Phone #:</Box>
                    <AppInput onChange={handlePhoneNumberChange} />
                </Grid>
                <Grid item xs={3}>
                    <Box>Carrier Contact Email Address:</Box>
                    <AppInput onChange={handleEmailAddressChange} />
                </Grid>
                <Grid item xs={6} md={1} style={{ textAlign: 'left', alignContent: 'end'}}>
                    <AppButton onClick={handleSearchClick}>Search</AppButton>
                </Grid>
                <Grid item xs={12}>
                    <CarrierContactListing
                        count={shipmentCount}
                        data={shipments}
                        orderBy={orderBy}
                        order={order}
                        rowsPerPage={limit}
                        page={offset / limit}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        onSort={handleSortChange}
                        associateId={associate.id} />
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};
export default CarrierContactReport
