import React, {useEffect} from 'react';
import "./NewNoteTooltipIcon.scss";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {Assignment} from "@material-ui/icons";


const NewNoteTooltipIcon = React.forwardRef((props, ref) => {

    const {
        loadTrackingAssociateName,
        loadTrackingPhase,
        loadTrackingNote,
        loadTrackingDate
    } = props;

    const loadTrackingFormattedDate = loadTrackingDate
        ? new Date(loadTrackingDate).toLocaleString("en-US", { dateStyle: "short", timeStyle: "short", })
        : null;

    const [showTooltip, setShowTooltip] = React.useState(false);
    const toggleTooltip = (show) => {
        setShowTooltip(show);
    };

    useEffect(() => {}, [showTooltip]);

    return <div className={"new-note-icon-container"}>
        <AppIconButton onMouseEnter={() => toggleTooltip(true)} onMouseLeave={() => toggleTooltip(false)} Icon={Assignment}/>
        {showTooltip && <div className={"new-note-tooltip"}>
            <p>{loadTrackingAssociateName} {loadTrackingFormattedDate}</p>
            <hr/>
            <p><strong>Phase:</strong> {loadTrackingPhase}</p>
            <hr/>
            <p><strong>Note:</strong> {loadTrackingNote}</p>
        </div>}
    </div>
});

export default NewNoteTooltipIcon;
