import React from 'react';
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {
    ShipmentNumberCell
} from "../../../open-load-board/includes/listingCells/multiUseListingCells";
import "./ShipmentsListingPage.scss";
import {
    RefNumCell,
    TrackingNoteAuditCell,
    RateConCell,
    PodCell,
    CarrierInvoiceCell
} from "./cells/shipmentListingGridCells";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import NewNoteTooltipIcon from "./NewNoteTooltipIcon";


const ShipmentsListingPage = React.forwardRef((props, ref) => {
    const GridColumns = [
        {
            field: 'bolNumber',
            title: 'Ship #',
            type: 'custom',
            width: 55,
            cell: ShipmentNumberCell
        },
        {
            field: 'associateShortName',
            title: 'Rep',
            type: 'custom',
            cell: (props) =>
              <td>
                <span>
                    <AgentTooltipComponent content={props.dataItem.associateShortName} agentId={props.dataItem.associateId}/>
                </span>
              </td>,
            width: 40,
        },
        {
            field: 'bolDate',
            title: 'Pickup Date',
            type: 'date',
            width: 80,
        },
        {
            field: 'loadTrackingPhase',
            title: 'Phase',
            type: 'text',
            width: 100,
        },
        {
            field: 'companyName',
            title: 'Customer',
            type: 'link',
            url: (props) => `/address-book/${props.companyId}/details`,
            width: 250,
        },
        {
            field: 'customerRep',
            title: 'Contact',
            type: 'custom',
            cell: (props) => <td><span>{props.dataItem.customerRep ?? ' '}</span></td>,
            width: 120,
        },
        {
            field: 'refNum1',
            title: 'Reference #',
            type: 'custom',
            width: 150,
            cell: RefNumCell
        },
        {
            field: 'freightCategory',
            title: 'Type',
            type: 'text',
            width: 40,
        },
        {
            headerClassName: "tgf-kendo-grid_column-header_centered",
            field: "signedRateCon",
            title: "RC",
            type: "custom",
            cell: RateConCell,
            width: 35,
        },
        {
            headerClassName: "tgf-kendo-grid_column-header_centered",
            field: "pod",
            title: "POD",
            type: "custom",
            cell: PodCell,
            width: 35,
        },
        {
            headerClassName: "tgf-kendo-grid_column-header_centered",
            field: "carrierInvoice",
            title: "Car Inv",
            type: "custom",
            cell: CarrierInvoiceCell,
            width: 35,
        },
        {
            field: 'carrierName',
            title: 'Carrier',
            type: 'link',
            url: (props) => `/carrier/${props.carrierMcNumber}/compliance`,
            width: 175,
        },
        {
            field: 'proNumber',
            title: 'Tracking #',
            type: 'custom',
            cell: (props) => <td><span>{props.dataItem.proNumber ?? ' '}</span></td>,
            width: 130,
        },
        {
            field: '',
            title: 'New Note',
            type: 'custom',
            cell: (props) =>
            <td>
                <NewNoteTooltipIcon
                    loadTrackingAssociateName={props.dataItem.loadTrackingAssociateShortName}
                    loadTrackingNote={props.dataItem.loadTrackingNote}
                    loadTrackingPhase={props.dataItem.loadTrackingPhase}
                    loadTrackingDate={props.dataItem.loadTrackingCreateDate} />
            </td>,
            minWidth: 40,
        },
        {
            field: 'loadTrackingNote',
            title: 'Notes',
            type: 'custom',
            cell: (props) => <td><span>{props.dataItem.loadTrackingNote ?? ' '}</span></td>,
            minWidth: 140,
        },
        {
            field: 'loadTrackingAssociateShortName',
            title: 'Rep / Date',
            type: 'custom',
            cell: TrackingNoteAuditCell,
            width: 200,
        }
    ];

    return <div>
        <TgfDataGrid
          className={"shipments-listing-grid"}
          ref={ref}
          onLoadDataPage={props.onLoadGridData}
          onApplyAdditionalDataStateAsOdata={props.applyAdditionalFilters}
          gridColumns={GridColumns}
          sort={[{field: "bolNumber", dir: "desc"}]}
          pageable={{
              pageSize: 20,
              buttonCount: 10,
              pageSizes: [5, 10, 20, 50, 100, 500],
          }}
        />
    </div>
});

export default ShipmentsListingPage;
